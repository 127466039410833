<template>
    <div class="create-order-dialog">
      <div class="order-type-label">
        <button v-if="orderType === 'buy'" class="live-order-list-buy">
          <img 
            alt="Buy order"
            height="16"
            src="@/assets/svg/v2/products/buyOrder.svg"
            class="light-icon"
            width="16"
          />
          <p>{{ $t('search.buy') }}</p>
        </button>

        <button v-if="orderType === 'sell'" class="live-order-list-sell">
          <img
            alt="Sell order"
            height="16"
            src="@/assets/svg/v2/products/sellOrder.svg"
            class="light-icon"
            width="16"
          />
          <p>{{ $t('search.sell') }}</p>
        </button>

        <button v-if="orderType === 'taker'" class="live-order-list-giver">
          <img
            alt="Sell order"
            height="16"
            src="@/assets/svg/v2/products/swap.svg"
            class="themed-img"
            width="16"
          />
          <p>Giver</p>
        </button>

        <button v-if="orderType === 'giver'" class="live-order-list-taker">
          <img
            alt="Sell order"
            height="16"
            src="@/assets/svg/v2/products/swap.svg"
            class="themed-img"
            width="16"
          />
          <p>Taker</p>
        </button>

        <button v-if="orderType === 'blocktrade'" class="live-order-list-blocktrade">
          <img
            alt="Swap taker order"
            height="16"
            src="@/assets/svg/v2/products/blocktrade.svg"
            class="light-icon"
            width="16"
          />
          <p>BlockTrade</p>
        </button>

        <el-popover placement="bottom" width="350" trigger="click">
          <template #reference>
            <div>
              <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img" width="23" />
            </div>
          </template>
          <div class="explain-content-order">
            <firstTimeCreateOrder readOnly :type="returnFirstTimeOrderType" />
          </div>
        </el-popover>
      </div>

      <div class="order-container">
        <div class="order-container-bottle" v-for="(bottle, n) in form.bottles" :key="n">
          <img
            v-if="bottle.files_attachments.length > 0"
            :alt="bottle.title"
            :src="bottle.files_attachments[0]"
            height="58"
            width="58"
          />
          <img
            v-else
            :src="require('@/assets/svg/v2/bottle.svg')"
            alt="Product image not found"
            class="empty-bottle-picture"
            height="58"
            width="58"
          />

          <div class="bottle-container">
            <h4>{{ bottle.title }}</h4>

            <div class="bottle-price-quantity-container">
                <p>{{ bottle.ticker }}</p>

                <el-input
                    v-model="bottle.price"
                    :class="{ errorInput: bottle.priceRegex }"
                    :placeholder="$t('product_page.orders.bottle_price')"
                    class="sell-order-input"
                />

                <el-input
                    v-model="bottle.quantity"
                    :disabled="orderType !== 'blocktrade'"
                    :class="{ errorInput: bottle.quantityRegex }"
                    @change="quantityCheck()"
                    min="1"
                    max="36"
                    :placeholder="$t('product_page.orders.sell_quantity')"
                    type="number"
                    class="sell-order-input-small"
                />

                <button :style="n === 0 ? 'visibility: hidden;': 'visibility: visible;' " @click="deleteBottle(n)" class="delete-bottle">
                  <el-tooltip
                    :content="$t('product_page.orders.delete_order')"
                    class="tooltip"
                    effect="dark"
                    placement="top"
                  >
                    <img alt="Delete bottle" src="@/assets/svg/v2/delete.svg" class="themed-img" />
                  </el-tooltip>
              </button>
            </div>
          </div>
        </div>

        <form v-if="orderType === 'blocktrade'" action="" enctype="multipart/form-data" class="form-bottle-search">
          <h4>{{ $t('product_page.orders.add_bottle_basket') }}</h4>

          <searchBar
            :pageKey="'orderSearch'"
            :origin="'order-bottle-search'"
            @search="searchProducts"
          />

          <div class="compare-bottle-dialog-body" :class="{ disabledResultSearch: payload.search.length === 0 || getProducts['orderSearch'].length === 0}">
            <div v-if="getProducts['orderSearch'].length > 0" class="search-results">
              <div
                v-for="(product, n) in getProducts['orderSearch']"
                :key="n"
                @click.prevent="addNewReference(product)"
                class="search-result"
              >
                <div class="bloc-divider">
                  <div class="bloc-left">
                    <div class="search-picture">
                      <img
                        v-if="returnUrl(product.files_attachments)"
                        :src="returnUrl(product.files_attachments)"
                        :alt="product.title"
                        width="50"
                        height="50"
                      />
                      <img
                        v-else
                        src="@/assets/svg/v2/bottle.svg"
                        alt="Bottle"
                        width="50"
                        height="50"
                      />
                    </div>

                    <div class="title-ticker">
                      <h4>{{ product.title }}</h4>
                      <p>{{ product.ticker_maitre }}</p>
                    </div>
                  </div>

                  <p>{{ $t('commons.select') }}</p>
                </div>

                <el-divider
                  v-if="n < getProducts['orderSearch']?.length - 1"
                  class="divider"
                />
              </div>
            </div>

            <div v-else class="search-empty">
              <p>{{ $t('product_page.price_history.compare_dialog.no_select') }}</p>
            </div>
          </div>
        </form>

        <div class="package-options" v-for="(pack, p) in form.packages" :key="p">
          <h4>{{ $t('product_page.orders.package_title') }}</h4>

          <div class="package-dimensions">
            <div class="package-dimensions-container">
              <h4>{{ $t('product_page.orders.size_unit') }}</h4>
              <el-select
                v-model="pack.sizeUnit"
                class="package-order-input"
                :effect="darkmode ? 'dark' : ''"
              >
                <el-option
                  v-for="unit in getLists.unit_of_measurement_dimensions"
                  :key="unit.value"
                  :label="unit.value"
                  :value="unit.value"
                />
              </el-select>
            </div>

            <div class="package-dimensions-container">
              <h4>
                {{ $t('product_page.orders.length') }} ({{ pack.sizeUnit }})
              </h4>
              <el-input
                v-model="pack.length"
                :class="{ errorInput: pack.lengthRegex }"
                class="package-order-input"
                max="150"
                min="10"
                type="number"
              />
            </div>

            <div class="package-dimensions-container">
              <h4>
                {{ $t('product_page.orders.width') }} ({{ pack.sizeUnit }})
              </h4>
              <el-input
                v-model="pack.width"
                :class="{ errorInput: pack.widthRegex }"
                class="package-order-input"
                max="150"
                min="10"
                type="number"
              />
            </div>

            <div class="package-dimensions-container">
              <h4>
                {{ $t('product_page.orders.height') }} ({{ pack.sizeUnit }})
              </h4>
              <el-input
                v-model="pack.height"
                :class="{ errorInput: pack.heightRegex }"
                class="package-order-input"
                max="150"
                min="10"
                type="number"
              />
            </div>
          </div>

          <div class="package-weight">
            <div class="package-weight-container">
              <h4>{{ $t('product_page.orders.weight_unit') }}</h4>

              <el-select
                v-model="pack.weightUnit"
                class="generic-border"
                :effect="darkmode ? 'dark' : ''"
              >
                <el-option
                  v-for="unit in getLists.unit_of_measurement_weight"
                  :key="unit.value"
                  :label="unit.value"
                  :value="unit.value"
                />
              </el-select>
            </div>

            <div class="package-weight-container">
              <h4>
                {{ $t('product_page.orders.sell_weight') }} ({{ pack.weightUnit }})
              </h4>
              <el-input
                v-model="pack.weight"
                :class="{ errorInput: pack.weightRegex }"
                class="package-order-input"
                max="20"
                min="1"
                type="number"
              />
            </div>
          </div>

          <div v-if="p === 0 && returnAllQuantity > 1" class="add-package-container">
            <el-checkbox v-model="form.addPackage"/>
            <p>{{ $t('product_page.orders.add_package') }}</p>
          </div>
        </div>

        <div class="form-options-create-order">
          <h4 class="options-title">{{ $t('product_page.orders.sell_options') }}</h4>
          <div class="sell-end-date-bloc">

            <div v-if="getUserDetails.identity.account_type === 0 && orderType !== 'buy'" class="options-anonymous-bloc">
              <div class="options-anonymous-tooltip">
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('product_page.orders.anonymous_description')"
                  placement="top"
                >
                  <h4 class="anonymous-bloc-title-tooltip">
                    <img alt="Anonymous transaction" src="@/assets/svg/v2/orders/anonymous_transaction.svg" class="themed-img" />
                    {{ $t('product_page.orders.anonymous_transaction') }}
                  </h4>
                </el-tooltip>
              </div>

              <el-switch
                v-model="form.anonymous"
                class="switch"
                style="
                  --el-switch-on-color: #2667ff;
                  --el-switch-off-color: #555555;
                "
              />
            </div>

            <div class="sell-end-date-inputs">
              <div class="sell-end-date-actions">
                <div class="sell-end-date-select">
                  <img alt="End date" src="@/assets/svg/v2/settings/hour.svg" class="themed-img"/>
                  <h4>{{ $t('product_page.orders.sell_end_date') }}</h4>
                </div>
                <el-switch
                  v-model="form.endDate"
                  class="switch"
                  style="
                    --el-switch-on-color: #2667ff;
                    --el-switch-off-color: #555555;
                  "
                />
              </div>
              <div v-if="form.endDate" class="sell-select-end-date">
                <el-date-picker
                  v-model="form.date"
                  :disabled-date="disabledDate"
                  class="generic-border"
                  format="DD/MM/YYYY"
                  :placeholder="$t('product_page.orders.choose_date')"
                  type="date"
                  value-format="YYYY-MM-DD"
                />

                <el-input
                  v-model="form.time"
                  clearable
                  class="sell-order-input-small"
                  placeholder="12:00"
                  value-format="HH:mm"
                  maxlength="5"
                />
              </div>
              <span
                v-if="formRegex.dateBeforeNow"
                class="error"
                style="margin: 10px 0"
                >{{ $t('product_page.orders.add_min_one_hour') }}</span
              >
            </div>
          </div>
        </div>

        <div v-if="orderType !== 'buy'" class="file-zone" :class="{ errorInput: formRegex.pictures }">
          <div>
            <label
              for="input-file"
              id="drop-area"
              @dragover.prevent
              @drop="files($event)"
            >
              <input
                ref="file"
                :multiple="true"
                accept="image/jpg, image/jpeg, image/png"
                class="custom-file-input"
                name="document"
                type="file"
                id="input-file"
                @change="files"
              />
              <img
                src="@/assets/svg/v2/portfolio/import.svg"
                class="themed-img"
                alt="File Icon"
              />
              <div class="input-text">
                <p>
                  <span>{{ $t('product_page.orders.load_a_file') }}</span>
                  {{ $t('product_page.orders.or_drag_drop') }}
                </p>
                <p class="text-grey">{{ $t('product_page.orders.file_format_allowed') }}</p>
              </div>
            </label>
            <p class="text-grey-required-pictures" v-if="selectedFiles.length < (getProduct.allowed_min_pictures || 4) ">{{ $t('product_page.orders.min_pictures_required_1') }} {{ getProduct.allowed_min_pictures ? getProduct.allowed_min_pictures : 4 }} {{ $t('product_page.orders.min_pictures_required_2') }}</p>
          </div>
        </div>

        <div v-if="selectedFiles.length > 0">
          <div class="images-previews">
            <div
              v-for="(file, index) in selectedFiles"
              :key="index"
              class="image-preview"
              @mouseenter="showDeleteButton(index)"
              @mouseleave="hideDeleteButton(index)"
            >
              <img
                :src="file.preview"
                alt="Selected Image"
                class="image-preview"
              />

              <button class="delete-button" @click="removeFile(index)">
                <img
                  :src="require(`@/assets/svg/v2/delete.svg`)"
                  class="themed-img"
                  alt="Delete picture"
                />
              </button>
            </div>
          </div>
        </div>

        <button
          :disabled="isLoading"
          v-loading="isLoading"
          class="create-order-btn generic-btn"
          @click.prevent="createOrder()"
        >
          <span>{{ returnCreateOrderLabel }}</span>
        </button>
      </div>
    </div>
  </template>
  
<script>
import { ElMessage } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'
import searchBar from '../search_V2.vue'
import { find } from 'lodash'
import m from 'moment'
import { inject } from "vue"
import firstTimeCreateOrder from '@/components/modules/dialogs/firstTimeCreateOrder.vue'
  
  export default {
    emits: ['close', 'bottlesInfos'],
    props: {
      orderType: {
        type: String,
        validator(v) {
            return ['buy', 'sell', 'taker', 'giver', 'blocktrade'].includes(v)
        },
        required: true
      },
      isUpdate: {
        type: Boolean,
        validator(v) {
          return [true, false].includes(v)
        },
        required: false,
        default: false
      },
      isUpdateData: {
        type: Object,
        required: false
      },
      presetPrice: {
        type: Object,
        required: false
      }
    },
    components: {
      searchBar,
      firstTimeCreateOrder
    },
    data() {
      return {
        darkmode: inject("darkmode"),
        selectedFiles: [],
        isLoading: false,
        payload: {
          type: 'order-search',
          search: '',
        },
        form: {
          orderType: null,
          bottles: [],
          packages: [
            {
              width: null,
              height: null,
              length: null,
              weight: null,
              sizeUnit: 'CM',
              weightUnit: 'KGS'
            }
          ],
          addPackage: false,
          anonymous: false,
          endDate: false,
          date: null,
          time: '00:00'
        },
        bottleForm: {
          price: null,
          title: null,
          quantity: 1,
          isbn: null,
          ticker: null
        },
        formRegex: {
          timeRegex: false,
          pictures: false
        },
      }
    },
    mounted() {
      
      if (this.orderType === 'buy') {
        this.form.packages = [];
        this.form.bottles = this.form.bottles.slice(0, 1);
      } else if (this.orderType === 'sell') {
        if (this.presetPrice) {
          this.form.bottles.push(this.presetPrice);
        }

        this.form.bottles = this.form.bottles.slice(0, 1);

      } else {
        if (this.form.packages.length === 0) {
          this.pushNewPackage();
        }
      }

      this.checkIfIsUpdatedOrder();
    },
    computed: {
      ...mapGetters({
        getProduct: 'getProduct',
        getProducts: 'getProducts',
        getSearchPending: 'getSearchPending',
        getLists: 'getLists',
        getUserDetails: 'getUserDetails',
        getOrder: 'getOrder'
      }),
      returnAllQuantity() {
        let allQantity = 0;

        this.form.bottles.forEach((b) => {
          allQantity += Number(b.quantity);
        })

        return allQantity;
      },
      returnCreateOrderLabel() {
        if (this.isUpdate) {
          return this.$t('product_page.orders.update_order');
        } else {
          if (this.orderType === 'sell' || this.orderType === 'blocktrade') {
            return this.$t('product_page.orders.sell_create_order');
          }
          return this.$t('product_page.orders.buy_create_order');
        }
      },
      returnFirstTimeOrderType() {
        if (this.orderType === 'buy' || this.orderType === 'sell') {
          return this.orderType;
        }
        return 'sell';
      }
    },
    watch: {
      'form.addPackage': function() {
        this.quantityCheck();
      },
      'form.bottles': {
        handler() {
          this.quantityCheck();
        },
        deep: true
      },
      orderType() {
        if (this.orderType === 'buy') {
          this.selectedFiles = [];
          this.form.packages = [];
          this.form.bottles = this.form.bottles.slice(0, 1);
        } else if (this.orderType === 'sell') {
          if (this.presetPrice) {
            this.form.bottles.push(this.presetPrice);
          }

          this.form.bottles = this.form.bottles.slice(0, 1);
          this.pushNewPackage();
        } else {
          if (this.form.packages.length === 0) {
            this.pushNewPackage();
          }
        }
      },
      isUpdateData: {
        handler() {
          this.checkIfIsUpdatedOrder();
        },
        deep: true
      }
    },
    methods: {
      ...mapActions({
        createOrder: 'createBuyOrder',
        createOrderV2: 'createOrderV2',
        updateOrderV2: 'updateOrderV2',
        search: 'autocompleteSearchAuth',
        getOrderActions: 'getOrder',
        getOrderHistory: 'getOrderHistory'
      }),
      returnUrl(pictures) {
        if (pictures) {
          return pictures[0]
        }
      },
      deleteBottle(n) {
        if (this.form.bottles.length > 1) {
          this.form.bottles.splice(n, 1);
        }
      },
      disabledDate(time) {
        const today = new Date()
        const threeMonthsFromNow = new Date(
          today.getFullYear(),
          today.getMonth() + 3,
          today.getDate()
        )
        return (
          time.getTime() < today.getTime() ||
          time.getTime() > threeMonthsFromNow.getTime()
        )
      },
      quantityCheck() {
        let allQantity = 0;

        this.form.bottles.forEach((b) => {
          allQantity += Number(b.quantity);
        })        

        if ((allQantity > 6 && this.form.packages.length === 1) || (allQantity > 1 && this.form.addPackage && this.form.packages.length === 1)) {

          this.pushNewPackage();
          this.form.addPackage = true;

        } else if (allQantity < 7 && !this.form.addPackage) {

          this.form.packages.splice(1, 1);

        } else if (allQantity === 1 && this.form.packages.length === 2) {

          this.form.addPackage = false;

        } else if (allQantity > 6) {

          this.form.addPackage = true;

        }
      },
      async checkIfIsUpdatedOrder() {
        if (this.isUpdate) {
          this.resetForm();

          const payload = {
            uuid: this.isUpdateData.uuid,
            shipment: 0
          }

          await this.getOrderActions(payload).then(() => {

            const o = this.getOrder[0];          
            
            this.form.orderType = o.order_type;

            o.bottles.forEach((b) => {
              this.form.bottles.push(b);
            })

            this.form.packages = [];
            if (this.orderType !== 'buy') {
              o.packages.forEach((p) => {              
                this.form.packages.push(p.package_dimensions);
              })
            }

            if (o.packages.length > 1 && this.orderType !== 'buy') {
              this.form.addPackage = true;
            }

            if (o.anonymous === 1) {
              this.form.anonymous = true;
            }

            if (o.countdown) {
              this.form.endDate = true;
              this.form.date = m(o.countdown).format('YYYY-MM-DD');
              this.form.time = m(o.countdown).format('HH:mm');
            }
                        
            if (o.order_type !== 'buy' && o.order_type !== 0 && o.pictures) {
              this.selectedFiles = o.pictures.map((url) => ({ preview: url }));
            }
          })
        
        } else {
          this.addFirstBottle();
        }
      },
      pushNewPackage() {
        this.form.packages.push({
          width: null,
          height: null,
          length: null,
          weight: null,
          sizeUnit: 'CM',
          weightUnit: 'KGS',

          widthRegex: false,
          heightRegex: false,
          lengthRegex: false,
          weightRegex: false
        })
      },
      addFirstBottle() {
        if (this.form.bottles.length === 0) {
          this.form.bottles.push({
              price: null,
              title: this.getProduct.title,
              quantity: 1,
              isbn: this.getProduct.isbn,
              ticker: this.getProduct.ticker,
              files_attachments: this.getProduct.files_attachments || [],
              allowed_min_pictures: this.getProduct.allowed_min_pictures,
  
              priceRegex: false,
              quantityRegex: false
          })
        }
      },
      addNewReference(product) {
        let allQantity = 0;
        const findSameReference = find(this.form.bottles, (b) => {
          return product.isbn === b.isbn;
        })


        this.form.bottles.forEach((b) => {
          allQantity += Number(b.quantity);
        })
        

        if ((allQantity >= 18 && this.getUserDetails.identity.account_type === 0) || (allQantity >= 36 && this.getUserDetails.identity.account_type === 1)) {

          ElMessage({
            message: this.$t('product_page.orders.maximum_sell_quantity'),
            type: 'warning',
            grouping: true,
            duration: 4000,
          })

        } else {

          if (!findSameReference) {
            
            this.form.bottles.push({
                price: null,
                title: product.title,
                quantity: 1,
                isbn: product.isbn,
                ticker: product.ticker_maitre,
                files_attachments: product.files_attachments || [],

                priceRegex: false,
                quantityRegex: false
            })
    
            this.$store.commit('RESET_SEARCH', { key: 'orderSearch', products: [] })
  
          } else {
            ElMessage({
              message: this.$t('product_page.orders.duplicate_reference'),
              type: 'warning',
              grouping: true,
              duration: 4000,
            })
          }

        }
      },
      async createOrder() {
        const rPrice = /^(50|[5-9][0-9]|[1-9][0-9]{2,3}|[12][0-9]{4}|30000)$/;
        const rQuantity = /^[0-9]{1,2}$/;
        const rDimensions = /^[1-9][0-9]{0,3}$/;
        const rTime = /^(?:[0-9]{2}):(?:[0-9]{2})$/;
        const rWeight = /\b\d+(\.\d{1,2})?\b/;

        let checkInputs = 0;
        let canCreateOrder = 0;
        let picturesMin = 0;
        let savedPictures = 0;

        this.form.bottles.forEach((b) => {
          checkInputs += 2;
          picturesMin += b.allowed_min_pictures || 2;

          if (rPrice.test(b.price)) {
            b.priceRegex = false;
            canCreateOrder++;
          } else {
            b.priceRegex = true;
          }

          if (rQuantity.test(b.quantity)) {
            b.quantityRegex = false;
            canCreateOrder++;
          } else {
            b.quantityRegex = true;
          }
        })

        this.form.packages.forEach((p) => {
          checkInputs += 4;

          if (rDimensions.test(p.length)) {
            p.lengthRegex = false;
            canCreateOrder++;
          } else {
            p.lengthRegex = true;
          }

          if (rDimensions.test(p.width)) {
            p.widthRegex = false;
            canCreateOrder++;
          } else {
            p.widthRegex = true;
          }

          if (rDimensions.test(p.height)) {
            p.heightRegex = false;
            canCreateOrder++;
          } else {
            p.heightRegex = true;
          }

          if (rWeight.test(p.weight)) {
            p.weightRegex = false;
            canCreateOrder++;
          } else {
            p.weightRegex = true;
          }
        })

        if (this.form.endDate) {
          checkInputs += 1;

          if (rTime.test(this.form.time)) {
            this.formRegex.timeRegex = false;
            canCreateOrder++;
          } else {
            this.formRegex.timeRegex = true;
          }
        }
        
        const formData = new FormData();
        this.form.orderType = this.orderType;
        formData.append('createOrder', JSON.stringify(this.form));
        
        for (let fileCount = 0; fileCount < this.selectedFiles.length; fileCount++) {
          if (!this.selectedFiles[fileCount].preview.includes('blob:')) {
            formData.append('saved_pictures_attachments', this.selectedFiles[fileCount].preview);
            savedPictures++;
          } else {
            formData.append('files', this.selectedFiles[fileCount].file)
          }
        }

        if (this.isUpdate && this.orderType !== 'buy' && this.orderType !== 'blocktrade') {
          checkInputs += 1;

          if (this.selectedFiles.length >= picturesMin || savedPictures >= picturesMin) {
            
            canCreateOrder++;
            this.formRegex.pictures = false;

          } else {
            this.formRegex.pictures = true;
          }
          
        } else if (this.orderType !== 'buy' && this.orderType !== 'blocktrade') {
          checkInputs += 1;

          if ((this.selectedFiles.length >= picturesMin || savedPictures >= picturesMin) && this.orderType !== 'blocktrade') {

            canCreateOrder++;
            this.formRegex.pictures = false;

          } else {
            this.formRegex.pictures = true;
          }

        } else if (this.orderType === 'blocktrade') {

          if (this.selectedFiles.length >= picturesMin || savedPictures >= picturesMin) {

            canCreateOrder++;
            this.formRegex.pictures = false;

          } else {
            this.formRegex.pictures = true;
          }

        }

        this.isLoading = true;
        this.$emit('bottlesInfos', this.form.bottles);
        
        if (this.isUpdate && canCreateOrder >= checkInputs) {

          await this.updateOrderV2(formData).then(async() => {
            
            this.getOrderHistory();
            this.$store.commit('CREATE_ORDER_DIALOG', false);
            this.$emit('close');
  
          }).catch((error) => {
  
            if (error.response.status === 406) {
              this.$store.commit('CREATE_ORDER_DIALOG', false);
              this.$router.push('/register');
            }
            
          })
          .finally(() => {
            this.isLoading = false;
          })
          
        } else if (canCreateOrder >= checkInputs) {
          
          await this.createOrderV2(formData).then(async(order) => {
            
            if (order.blocktrade_token) {
              this.$store.commit('CREATE_ORDER_DIALOG', order.blocktrade_token);
            } else {
              this.$store.commit('CREATE_ORDER_DIALOG', false);
            }
  
            this.$emit('close');
  
          }).catch((error) => {
  
            if (error.response.status === 406) {
              this.$store.commit('CREATE_ORDER_DIALOG', false);
              this.$router.push('/register');
            }
            
          })
          .finally(() => {
            this.isLoading = false;
          })
        } else {

          ElMessage({
              message: this.$t('product_page.orders.wrong_value_order'),
              type: 'warning',
              grouping: true,
              duration: 10000,
            })
          
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
        }
      },
      searchProducts(searchProducts, key) {
        this.payload.search = searchProducts.value ? searchProducts.value : searchProducts.search;

        if (searchProducts.value?.length > 0 || searchProducts.search?.length > 0) {

          if (!this.getSearchPending['orderSearch']) {

            const formattedPayload = {
              type: key,
              search: searchProducts.value ? searchProducts.value : searchProducts.search,
              filters: {
                country: null,
                age: null,
                vintage: null,
                single_cask: false
              }
            }

            this.search(formattedPayload);
          }

        } else {
          this.$store.commit('RESET_SEARCH', { key: key, products: [] })
        }
      },
      files(event) {
        if (event.dataTransfer) {
          event.preventDefault();
          this.$refs.file.files = event.dataTransfer.files;
        }

        for (let fileCount = 0; fileCount < this.$refs.file.files.length; fileCount++) {
          if (this.$refs.file.files) {
            const ext = /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/
            const fileName = this.$refs.file.files[fileCount].name
            const fileSize = this.$refs.file.files[fileCount].size

            if (!ext.test(fileName) || fileSize > 7000000) {
              ElMessage({
                message: this.$t('product_page.orders.pictures_allowed'),
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const file = {
                file: this.$refs.file.files[fileCount],
                preview: URL.createObjectURL(this.$refs.file.files[fileCount]),
                name: this.$refs.file.files[fileCount].name,
              }

              const findSameName = find(this.selectedFiles, { name: file.name });

              if (!findSameName) {
                this.selectedFiles.push(file)
              } else {
                ElMessage({
                  message: this.$t('product_page.orders.duplicate_pictures'),
                  type: 'warning',
                  grouping: true,
                  duration: 4000,
                })
              }
            }
          }
        }
      },
      showDeleteButton(index) {
        this.selectedFiles[index] = {
          ...this.selectedFiles[index],
          showDeleteButton: true,
        }
      },
      hideDeleteButton(index) {
        this.selectedFiles[index] = {
          ...this.selectedFiles[index],
          showDeleteButton: false,
        }
      },
      removeFile(index) {
        this.selectedFiles.splice(index, 1);
      },
      resetForm() {
        this.form = {
          orderType: null,
          bottles: [],
          packages: [
            {
              width: null,
              height: null,
              length: null,
              weight: null,
              sizeUnit: 'CM',
              weightUnit: 'KGS'
            }
          ],
          addPackage: false,
          anonymous: false,
          endDate: false,
          date: null,
          time: null
        }
      }
    }
  }
</script>
  
<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.v-loading-spinner {
    border-radius: 52px;
}

.v-loading-spinner-inner {
    border-radius: 52px;
}

.create-order-dialog {
  z-index: 5;
  position: relative;

  .order-container {

    .order-container-bottle {
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      margin: 10px auto;
      margin-bottom: 10px;

      img {
        border-radius: 2px;
      }

      .bottle-container {
        margin-left: 16px;

        h4 {
          color: var(--text-color);
          font-weight: 600;
        }

        .bottle-price-quantity-container {
          display: flex;
          margin-top: 5px;

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--border);
            color: var(--text-color);
            border-radius: 6px;
            height: 32px;
            min-width: 130px;
            font-size: 15px;
          }

          .delete-bottle {
            margin-left: 10px;
            background: transparent;
            border: 0;
          }
        }
      }
    }

    .form-bottle-search {
      display: flex;
      flex-direction: column;
    }

    .create-order-btn {
      width: 194px;
      margin: 0px auto;
      margin-top: 20px;
    }
  }
}

.add-reference {
  width: 210px;
  margin: 0px auto;
  padding: 8px;
}

.compare-bottle-dialog-body {
  min-height: 96px;
  margin: 5px 0;

  .search-results {
    max-height: 150px;
    overflow-y: scroll;

    .search-result {

      .bloc-divider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 8px;
        padding-left: 0;
        border-radius: 4px;
        cursor: pointer;

        .bloc-left {
          display: flex;

          .title-ticker {

            h4 {
              font-size: 15px;
            }
          }

          .search-picture {
            margin-right: 15px;

            img {
              border-radius: 4px;
            }
          }
        }

        p {
          color: var(--subtitle-text-color);
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .divider {
        margin: 2px auto;
        border-color: var(--border);
      }
    }
  }

  .search-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;

    p {
      color: var(--subtitle-2-text-color);
      font-weight: 500;
    }
  }
}

.disabledResultSearch {
  display: none;
}

form {
    margin-bottom: 10px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid var(--border);
    border-top: 1px solid var(--border);

    h4 {
      margin-bottom: 5px;
    }
}

.package-options {
  border-bottom: 1px solid var(--border);
  padding-bottom: 15px;
  margin-top: 15px;
}

.add-package-container {
  display: flex;
  align-items: center;
  margin-top: 8px;

  p {
    margin-left: 8px;
  }
}

.package-dimensions, .package-weight {
  display: flex;
  justify-content: space-between;

  .package-dimensions-container, .package-weight-container {
    margin-top: 8px;
    width: 23%;

    h4 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}

.package-weight {
  justify-content: initial;

  .package-weight-container {
    margin-right: 3%;
  }
}

.sell-order-input, .package-order-input {
    border-radius: 5px;
    border: 1px solid var(--border);
    margin-left: 8px;
}

.package-order-input {
  margin-left: 0;
}

.sell-order-input-small {
    border-radius: 5px;
    border: 1px solid var(--border);
    width: 120px;
    margin-left: 8px;
}

.form-options-create-order {
  margin-top: 5px;

  .options-title {
    color: var(--text-color);
    margin: 10px auto;
  }

  .sell-end-date-bloc {

    .options-anonymous-bloc {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .options-anonymous-tooltip h4 {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;

        img {
          margin-right: 8px;
        }
      }
    }

    .sell-end-date-inputs {
      .sell-end-date-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        div {
          display: flex;
          align-items: center;

          h4 {
            margin-left: 8px;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }

      .sell-select-end-date {
        display: flex;
        margin-top: 12px;
        margin-bottom: 10px;
      }
    }
  }
}

.text-grey-required-pictures {
  margin-top: 5px;
  font-size: 13px;
  color: $alert-color;
}

.custom-file-input {
    width: 492px;
    height: 41px;
    margin-top: 13px;
    margin-bottom: 10px;
}

.file-zone {
  margin-bottom: 5px;
}

#drop-area {
  width: 98%;
}

.images-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 110px;
  text-align: center;
  position: relative;

  img {
    border-radius: 2px;
  }

  .delete-button {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 1px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

input[type='file']::file-selector-button {
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid var(--border);
    background: var(--background-color-3);
    color: var(--subtitle-text-color);
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    width: 492px;
    height: 41px;
}

input[type='file']::file-selector-button:hover {
    background: var(--background-color-3);
}

.activeType {
    background: var(--bg-constrast) !important;
    color: var(--text-contrast) !important;
}
  
@media screen and (max-width: 768px) {
  
    .custom-file-input,
    input[type='file']::file-selector-button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .create-order-dialog {
      align-items: flex-start;
  
      button {
  
        img {
          margin-top: 2px;
        }
      }
    }
  
    .create-order-dialog .order-container-bottle {
      align-items: flex-start;
  
      div {
        margin-left: 0;
      }
  
      p {
        max-width: 100%;
      }
    }
  
    .create-order-btn {
      width: 100%;
    }
}

.order-type-label {
  display: flex;
  margin-bottom: 15px;

  button {
    margin-right: 5px;
  }
}

.live-order-list-buy,
.live-order-list-sell,
.live-order-list-taker,
.live-order-list-giver,
.live-order-list-blocktrade {
  display: flex;
  border-radius: 50px;
  border: 1px solid var(--border);
  box-shadow: var(--box-shadow-6);
  height: 27px;
  width: max-content;
  padding: 4px 8px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  img {
    position: relative;
    z-index: 2; 
    border-radius: 50%;
  }

  p {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 500;
    margin-left: 6px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 6px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    z-index: 1; 
  }
}

.live-order-list-buy {
  background: $bg-gradient-buy;

  &::before {
    background: $buy-color;
  }
}

.live-order-list-sell {
  background: $bg-gradient-sell;

  &::before {
    background: $sell-color;
  }
}

.live-order-list-blocktrade {
  background: $bg-gradient-blocktrade;

  &::before {
    background: $primary-color;
  }
}

.sell-order-input {
  margin-right: 8px;
}
  
@media screen and (max-width: 500px) {
    .create-order-dialog {
      .order-container {

        .order-container-bottle {
          flex-direction: row;
          margin: -10px auto 10px auto;

          .bottle-container {
            margin-left: 5px;

            h4 {
              font-size: 14px;
            }

            .bottle-price-quantity-container {

              p {
                display: none;
              }
            }
          }
        }
      }
    }
  
    .create-order-dialog .order-container {
      padding: 0px;
    }
  
    .create-order-dialog {
      padding: 0px;
    }
  
    .order-container-bottle div h4 {
      font-size: 16px;
    }
  
    .order-container-bottle div p {
      font-size: 14px;
    }
  
    .create-order-btn {
      margin-top: 20px;
    
    }
  
}
</style>